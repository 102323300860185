import React, { Fragment } from "react";
import Text from "./Text";

interface PageSubheaderProps {
  header: string
  lastUpdate: string
}

const PageSubheader = ({header, lastUpdate}: PageSubheaderProps) => {
  return (
    <Fragment>
      <div className={`w-full bg-white flex items-center justify-center flex-col min-h-[150px] mt-[104px]`}>
        <Text
          text={header}
          weight={700}
          className={`text-[#282B59] text-center mb-4 text-[24px] text-center lg:text-[48px]`}
        />

        <Text
          text={lastUpdate}
          weight={400}
          className={`text-[#53557A] text-center text-[16px] text-center lg:text-[18px]`}
        />
      </div>
    </Fragment>
  )
}

export default PageSubheader;