export const FirstRouteSection = [`Lagos - Abeokuta`, `Lagos - Ibadan`, `Abeokuta - Ibadan`, `Abuja - Kaduna`]
export const SecondRouteSection = [`Warri - Itakpe`, `Marina - Mile 2`, `Mile 2 - Okokomaiko`, `Marina - Agbado`]
export const ThirdRouteSection = [`Marina - Airport`, `Yaba - Agege`, ' ', ' ']

export const faqQuestions = [
  {
    question: 'What is OrnaMap?',
    answer: 'OrnaMap is a mobile app designed to help you plan your train and ferry trips in Nigeria. The app allows you to search for train and ferry schedules, find suggested means of transport to the train station or harbor, and even book your trip in advance. With OrnaMap, you can easily plan your journey and enjoy the scenic views on your travels.'
  },

  {
    question: 'What features does OrnaMap offer?',
    answer: 'OrnaMap is a planning and scheduling app that suggests the best route and means to get to your desired train or ferry station. It displays the relevant information such as prices, arrival and departure time to aid your journey planning.'
  },

  {
    question: 'Are the train and ferry schedules up to date?',
    answer: 'Yes, the train and ferry schedules on OrnaMap are provided by the National Railway Corporation and are updated regularly. However, please note that they are subject to change.'
  },

  {
    question: 'Is OrnaMap free to use?',
    answer: 'Yes, OrnaMap is a free app to use. However, please note that booking fees may apply when you book your train or ferry tickets through the app.'
  },

  {
    question: 'What if I have more questions or issues with the app?',
    answer: 'If you have any further questions or issues with the app, please contact our support team through the "Contact Us" feature in the app. We\'ll be happy to assist you.'
  },
]