import { Fragment } from "react";
import {IoIosArrowForward} from "react-icons/io";
import Text from "../../components/global/Text";

interface UpdateButtonProps {
  to: string;
  image: string;
  header: string;
  message: string;
}

const UpdateButton = ({to, image, header, message}:UpdateButtonProps) => {
  return (
    <Fragment>
      <a href={to} target={`_blank`} className={`w-full flex p-4 bg-[#e7e7e4] rounded-lg my-1`}>
        <div className={`w-[24px] h-[24px] bg-[#0183BA] flex items-center justify-center mt-[5px]`}>
          <img src={image} alt={`Train`}/>
        </div>

        <div className={`flex item-center justify-center w-full`}>
          <div className={`flex flex-col ml-2 mr-auto`}>
            <Text text={header} weight={700} className={`!text-[16px] text-[#282B59] !leading-0 -tracking-[0.64px]`}/>
            <Text text={message} weight={500} className={`!text-[14px] !leading-0 text-[#53557A] -tracking-[0.56px]`}/>
          </div>

          <div className={`ml-auto`}>
            <IoIosArrowForward size={20} color={`#A9AABD`}/>
          </div>
        </div>
      </a>
    </Fragment>
  )
}

export default UpdateButton;