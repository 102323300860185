import axios from 'axios';

const host = window.location.host;

const instance = axios.create({
  baseURL: host.includes("localhost:") || host.includes("staging.web.ornamap.com") ? `https://qa.api.ornamap.com` : `https://prod.api.ornamap.com`,
  timeout: 7500,
});

export const axiosGetRequest = async (url: string, params?: any) => {
  try {
    const request = await instance.get(url, {
      params,
    });

    return request.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error;
    } else {
      throw new Error('An unexpected error occurred');
    }
  }
};

export const axiosPutRequest = async (url: string, data?: any) => {
  try {
    const request = await instance.put(url, data);

    return request.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw error;
    } else {
      throw new Error('An unexpected error occurred');
    }
  }
};
