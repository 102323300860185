import {Fragment, useEffect} from "react";
import Text from "../components/global/Text";

const Support = () => {
  useEffect(() => {
    window.location.href = `https://wa.me/+2347053980998`;
  }, []);

  return (
    <Fragment>
      <div className="w-screen h-screen flex items-center justify-center">
        <Text text="Redirecting to WhatsApp..."/>
      </div>
    </Fragment>
  )
}

export default Support;