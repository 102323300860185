import { useState, Fragment } from 'react'
import {Link} from "react-router-dom";
import OrnaMapLogo from '../../assets/img/global/logo-white-tint.svg'
import Text from "./Text";
import HamburgerButton from "./HamburgerButton";

interface HeaderProps {
  bgImg?: string
}


const HeaderWithBgImg = ({bgImg}:HeaderProps) => {
  const [navbarOpen, setNavbarOpen] = useState(false);

  return (
    <Fragment>
      <nav
        className={`fixed w-full z-20 top-0 left-0`}
        style={{
          backgroundImage: `url(${bgImg})`,
          backgroundSize: 'cover'
        }}
      >
        <div className={`max-w-screen-xl flex flex-wrap items-baseline justify-between mx-auto p-4 lg:h-[110px] lg:items-center ${navbarOpen ? 'h-[885px]' : ''}`}>
          <a href="/" className="flex items-center flex-row bg-[#282B59] shadow-md shadow-[#282b591a] rounded-[100px] lg:rounded-[30px] lg:py-[8px] lg:px-[24px]">
            <img
              alt={`OrnaMap Logo`}
              src={OrnaMapLogo}
              className={`lg:flex w-[40px] h-[40px] lg:w-[56px] lg:h-[56px]`}
            />

            <Text
              text={`OrnaMap`}
              weight={800}
              className="whitespace-nowrap text-white self-center text-[20px] hidden lg:flex lg:text-2xl"
            />
          </a>

          <div className="flex lg:order-2 bg-[#282B59] main-shadow rounded-lg">
            <button
              type="button"
              className="font-medium rounded-[8px] text-base font-bold px-10 py-2 text-center mr-3 lg:mr-0 bg-white hidden py-4 px-8 text-[#282B59] hover:text-[#696B8B] lg:flex"
            >
              Contact Us
            </button>

            <HamburgerButton text={navbarOpen ? 'Close' : 'Open'} click={() => setNavbarOpen(!navbarOpen)}  opened={navbarOpen}/>
          </div>

          <div
            className={`flex-col w-full h-full items-center px-8 py-4 justify-start rounded mt-0 bg-white lg:items-center lg:justify-center lg:rounded-[40px] lg:grid lg:w-auto lg:order-1 lg:items-center main-shadow ${navbarOpen ? '!flex lg:mt-0' : 'hidden'}`}
            id="navbar-sticky"
          >
            <ul
              className={`flex flex-col p-4 lg:p-0 font-medium rounded-lg lg:flex-row lg:space-x-8`}
            >
              <li className={`lg:mr-4`}>
                <Link to="/" className={`text-center block py-2 px-5 rounded lg:bg-transparent lg:p-0 text-[#282B59] hover:text-[#696B8B]`}>Home</Link>
              </li>

              <li className={`lg:ml-4`}>
                <Link to="https://blog.ornamap.com/" target={`_blank`} className={`text-center block py-2 px-5 rounded lg:bg-transparent lg:p-0 text-[#282B59] hover:text-[#696B8B]`}>Blog</Link>
              </li>

              <li className={`lg:ml-4`}>
                <Link to="/about" className={`text-center block py-2 px-5 rounded lg:bg-transparent lg:p-0 text-[#282B59] hover:text-[#696B8B]`}>About Us</Link>
              </li>
            </ul>

            <button type="button"
                    className="font-medium rounded-[8px] text-white text-base font-bold px-10 py-2 text-center mr-3 lg:mr-0 bg-[#12A628] flex hover:text-[#696B8B] lg:hidden ">Contact Us
            </button>
          </div>
        </div>
      </nav>
    </Fragment>
  )
}

export default HeaderWithBgImg;
