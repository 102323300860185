import React, {Fragment} from "react";
import CowryCard from "../../assets/img/homepage/cowry-card.png"
import {SpeakerSVG} from "../../assets/svg/Speakers";
import Text from "../global/Text";
import {BsApple, BsGooglePlay} from "react-icons/bs";

const Cowry = () => {
  return (
    <Fragment>
      <div className="flex flex-col-reverse max-w-screen-xl px-2 items-center justify-center w-full gap-4 lg:flex-row">
        <div className="flex w-full items-center justify-center rounded-[30px] bg-[#ECECF0] p-6 lg:h-[450px] lg:w-1/2">
          <img src={CowryCard} alt="Cowry Card" className="h-[300px] w-[300px] lg:h-[350px] lg:w-[350px]"/>
        </div>

        <div className="items-start h-full w-full p-2 justify-center gap-4 flex flex-col lg:w-1/2 lg:p-5">
          <div className="flex px-4 py-1 bg-[#59C169] rounded-2xl gap-2 justify-center items-center">
            Great news! <SpeakerSVG/>
          </div>

          <Text
            text={"Fund Your Cowry Card With OrnaMap"}
            className="text-[#282B59] text-base !font-extrabold lg:text-[48px] lg:leading-[60px] lg:!font-bold"
          />

          <Text
            text="You can now fund your Cowry Card directly on the OrnaMap app. Add funds anytime, anywhere, and enjoy a hassle-free travel experience across trains, ferries, and buses."
            weight={500}
            className="text-sm text-[#53557A]"
          />

          <div className="hidden flex-row gap-4 w-full justify-start my-4 lg:flex">
            <a
              typeof="button"
              target={`_blank`}
              href={`https://apps.apple.com/ng/app/ornamap/id6450780439`}
              className="min-w-[160px] text-white !bg-[#282B59] rounded-[7px] text-sm py-3 px-2 inline-flex items-center lg:px-5 lg:py-1 store-download-shadow"
            >
              <BsApple color="white" size={30} className={`mr-1 hidden lg:flex`}/>
              <BsApple color="white" size={20} className={`mr-1 flex lg:hidden`}/>

              <div className="flex flex-col ml-2">
                <Text
                  text="Available on"
                  weight={400}
                  className="text-white text-[12px] leading-[16px] mb-1 lg:text-[14px] lg:leading-[22px] lg:mb-0"
                />

                <Text
                  text="App Store"
                  weight={700}
                  className="text-[16px] text-white leading-[12px] lg:text-[18px] lg:leading-[25px]"
                />
              </div>
            </a>

            <a
              typeof="button"
              target={`_blank`}
              href={`https://play.google.com/store/apps/details?id=com.ornamap.android`}
              className="min-w-[160px] text-white !bg-[#282B59] rounded-[7px] text-sm py-3 px-2 inline-flex items-center lg:px-5 lg:py-1 store-download-shadow"
            >
              <BsGooglePlay color="white" size={30} className={`mr-1 hidden lg:flex`}/>
              <BsGooglePlay color="white" size={20} className={`mr-1 flex lg:hidden`}/>

              <div className="flex flex-col ml-2">
                <Text
                  text="Available on"
                  weight={400}
                  className="text-[12px] leading-[16px] mb-1 lg:text-[14px] lg:leading-[22px] lg:mb-0"
                />

                <Text
                  text="Play Store"
                  weight={700}
                  className="text-[16px] leading-[12px] lg:text-[18px] lg:leading-[25px]"
                />
              </div>
            </a>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Cowry;