import React, {Fragment} from "react";
import Text from "../global/Text";

const TermsAndConditions = () => {
  return (
    <Fragment>
      <div className={`max-w-screen-xl w-full bg-white rounded-lg px-4 py-8 lg:p-20`}>
        <Text
          text={`At OrnaMap, we are committed to providing a seamless and enjoyable experience for all our users. This includes ensuring that your transactions are processed smoothly and securely. However, we understand that technical issues may occasionally arise. Our refund policy is designed to address these situations and ensure your satisfaction.`}
          weight={400}
          className={`text-[#53557A] mb-4 text-[14px] text-justify lg:text-[24px]`}
        />

        {/*App Usage*/}
        <div className={`my-8`} id="usage">
          <Text
            text={"App Usage"}
            weight={800}
            className={`text-[#282B59] text-left my-8 text-[16px] lg:text-[25px]`}
          />

          <ul
            style={{
              listStyleType: 'disc'
            }}
            className={`ml-4 text-[#53557A] lg:ml-8`}
          >
            <li className={`text-[25px]`}>
              <Text
                text={"Purpose: OrnaMap is designed to provide information and services related to train and ferry transportation, aiming to enhance your travel experience."}
                weight={400}
                className={`text-[#53557A] my-2 text-[14px] text-justify lg:text-[24px]`}
              />
            </li>

            <li className={`text-[25px]`}>
              <Text
                text={"User Responsibilities: You are responsible for using the App in accordance with applicable laws and regulations. Any unauthorised use or violation of these Terms may result in the termination of your access to the App."}
                weight={400}
                className={`text-[#53557A] my-2 text-[14px] text-justify lg:text-[24px]`}
              />
            </li>
          </ul>
        </div>

        {/*Privacy*/}
        <div className={`my-8`} id="privacy">
          <Text
            text={"Privacy"}
            weight={800}
            className={`text-[#282B59] text-left my-8 text-[16px] lg:text-[25px]`}
          />
          <ul
            style={{
              listStyleType: 'disc'
            }}
            className={`ml-4 text-[#53557A] lg:ml-8`}
          >
            <li className={`text-[25px]`}>
              <Text
                text={"Data Collection: We may collect certain information from you as outlined in our Privacy Policy. By using the App, you consent to the collection, use, and disclosure of your personal information as described in the Privacy Policy."}
                weight={400}
                className={`text-[#53557A] text-left my-2 text-[14px] text-justify lg:text-[24px]`}
              />
            </li>

            <li className={`text-[25px]`}>
              <Text
                text={"Data Security: We prioritise the security of your data and employ industry-standard measures to protect your information. However, please be aware that no method of data transmission over the internet or electronic storage is entirely secure."}
                weight={400}
                className={`text-[#53557A] my-2 text-[14px] text-justify lg:text-[24px]`}
              />
            </li>
          </ul>
        </div>

        {/*Refund Policy*/}
        <div className={`my-8`} id="refund-policy">
          <Text
            text={"Refund Policy"}
            weight={800}
            className={`text-[#282B59] text-left my-8 text-[16px] lg:text-[25px]`}
          />

          <Text
            text={"At OrnaMap, we are committed to providing a seamless and enjoyable experience for all our users. This includes ensuring that your transactions are processed smoothly and securely. However, we understand that technical issues may occasionally arise. Our refund policy is designed to address these situations and ensure your satisfaction."}
            weight={400}
            className={`ml-4 text-[#53557A] mb-4 text-[14px] text-justify lg:text-[24px]`}
          />

          {/*Refund Eligibility*/}
          <Text
            text={"Refund Eligibility"}
            weight={800}
            className={`text-[#282B59] text-left my-4 ml-4 text-[14px] lg:text-[18px]`}
          />

          <ul
            style={{
              listStyleType: 'disc'
            }}
            className={`ml-10 text-[#53557A]`}
          >
            <li className={`text-[25px]`}>
              <Text
                text={"⁠Failed Transactions: If your transaction fails and the payment is deducted from your account, you are eligible for a refund."}
                weight={400}
                className={`text-[#53557A] my-2 text-[16px] text-justify lg:text-[20px]`}
              />
            </li>

            <li className={`text-[25px]`}>
              <Text
                text={"Duplicate Transactions: If you are charged multiple times for the same transaction, we will refund the duplicate charges."}
                weight={400}
                className={`text-[#53557A] my-2 text-[16px] text-justify lg:text-[20px]`}
              />
            </li>
          </ul>

          {/*Refund Process*/}
          <div className="flex flex-col">
            <Text
              text={"Refund Process"}
              weight={800}
              className={`text-[#282B59] text-left my-4 ml-4 text-[14px] lg:text-[18px]`}
            />

            <Text
              text={"To initiate the refund process, please contact our customer support team within 30 days of the transaction date. You will need to provide details of the transaction, including the date, time, and amount deducted, as well as any transaction reference numbers or receipts you have received. Our team will then investigate the issue and process your refund as quickly as possible."}
              weight={400}
              className={`text-[#53557A] my-2 ml-6 text-[16px] text-justify lg:ml-8 lg:text-[20px]`}
            />

            <Text
              text={"We aim to resolve all refund requests within 7 to 10 business days. However, please be aware that the time it takes for the refund to appear in your account may vary depending on your bank or payment provider. We will keep you informed throughout the process and provide updates on the status of your refund."}
              weight={400}
              className={`text-[#53557A] my-2 ml-6 text-[16px] text-justify lg:ml-8 lg:text-[20px]`}
            />
          </div>

          {/*Contact Information*/}
          <div className="flex flex-col">
            <Text
              text={"Contact Information"}
              weight={800}
              className={`text-[#282B59] text-left my-4 ml-4 text-[14px] lg:text-[18px]`}
            />

            <p
              className="leading-7 !no-underline font-nunito text-base not-italic normal-nums tracking-normal indent-0 break-normal text-[#53557A] my-2 ml-6 text-[16px] text-justify lg:ml-8 lg:text-[20px]"
              style={{
                fontWeight: 400
              }}>
              For any refund-related queries or to report a transaction issue, please contact our customer support team
              at <a href="mailto:support@ornamap.com" className="text-[#008751] hover:text-black">support@ornamap.com</a>.
              Our customer support is available Monday to Friday, from 9:00 AM to 5:00 PM (WAT).
            </p>
          </div>

          {/*Important Notes*/}
          <div className="flex flex-col">
            <Text
              text={"Important Notes"}
              weight={800}
              className={`text-[#282B59] text-left my-4 ml-4 text-[14px] lg:text-[18px]`}
            />

            <Text
              text={"Refunds are only applicable for failed transactions where money has been deducted from your account.\n" +
                "OrnaMap reserves the right to amend this refund policy at any time. Any changes will be communicated through our app and website."}
              weight={400}
              className={`text-[#53557A] my-2 ml-6 text-[16px] text-justify lg:ml-8 lg:text-[20px]`}
            />

            <Text
              text={"Thank you for choosing OrnaMap. We appreciate your understanding and cooperation in ensuring a smooth refund process."}
              weight={400}
              className={`text-[#53557A] my-2 ml-6 text-[16px] text-justify lg:ml-8 lg:text-[20px]`}
            />
          </div>
        </div>

        <div className={`my-8`} id="ip">
          <Text
            text={"Intellectual Property"}
            weight={800}
            className={`text-[#282B59] text-left my-4 ml-4 text-[14px] lg:text-[18px]`}
          />
          <ul
            style={{
              listStyleType: 'disc'
            }}
            className={`ml-8  text-[#53557A]`}
          >
            <li className={`text-[25px]`}>
              <Text
                text={"Ownership: The App and its content, including but not limited to text, graphics, logos, and images, are protected by intellectual property laws and are the exclusive property of OrnaMap Ltd. All rights not expressly granted to you under these Terms are reserved by OrnaMap Ltd."}
                weight={400}
                className={`text-[#53557A] my-2 text-[14px] text-justify lg:text-[24px]`}
              />
            </li>

            <li className={`text-[25px]`}>
              <Text
                text={"Limited License: We grant you a limited, non-exclusive, non-transferable license to use the App for personal, non-commercial purposes. You may not modify, reproduce, distribute, create derivative works, or reverse engineer any part of the App."}
                weight={400}
                className={`text-[#53557A] my-2 text-[14px] text-justify lg:text-[24px]`}
              />
            </li>
          </ul>
        </div>

        <div className={`my-8`} id="liability">
          <Text
            text={"Limitation of Liability"}
            weight={800}
            className={`text-[#282B59] text-left my-8 text-[16px] lg:text-[25px]`}
          />
          <ul
            style={{
              listStyleType: 'disc'
            }}
            className={`ml-8  text-[#53557A]`}
          >
            <li className={`text-[25px]`}>
              <Text
                text={"Disclaimer: The information and services provided by OrnaMap are for general informational purposes only. We make no warranties or representations about the accuracy, reliability, completeness, or timeliness of the content."}
                weight={400}
                className={`text-[#53557A] my-2 text-[14px] text-justify lg:text-[24px]`}
              />
            </li>

            <li className={`text-[25px]`}>
              <Text
                text={"Indemnification: You agree to indemnify, defend, and hold OrnaMap Ltd. harmless from any claims, liabilities, damages, losses, or expenses arising out of or in connection with your use of the App."}
                weight={400}
                className={`text-[#53557A] my-2 text-[14px] text-justify lg:text-[24px]`}
              />
            </li>
          </ul>
        </div>

        <div className={`mb-6`} id="third-party">
          <Text
            text={"Third-Party Links"}
            weight={800}
            className={`text-[#282B59] text-left my-8 text-[16px] lg:text-[25px]`}
          />

          <Text
            text={"Our app may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these websites."}
            weight={400}
            className={`text-[#53557A] my-2 text-[14px] text-justify lg:text-[24px]`}
          />
        </div>

        <div className={`my-8`} id="jurisdiction">
          <Text
            text={"Governing Law and Jurisdiction"}
            weight={800}
            className={`text-[#282B59] text-left my-8 text-[16px] lg:text-[25px]`}
          />
          <ul
            style={{
              listStyleType: 'disc'
            }}
            className={`ml-8  text-[#53557A]`}
          >
            <li className={`text-[25px]`}>
              <Text
                text={"Governing Law: These Terms and any disputes arising from or relating to them shall be governed by and construed in accordance with the laws of Nigeria, Lagos state."}
                weight={400}
                className={`text-[#53557A] my-2 text-[14px] text-justify lg:text-[24px]`}
              />
            </li>

            <li className={`text-[25px]`}>
              <Text
                text={"Dispute Resolution: Any disputes or claims arising from or related to these Terms or your use of the App shall be subject to the exclusive jurisdiction of the courts in Nigeria, Lagos state."}
                weight={400}
                className={`text-[#53557A] my-2 text-[14px] text-justify lg:text-[24px]`}
              />
            </li>
          </ul>
        </div>

        {/*Anti Money Laundering Act*/}
        <div className="mb-8">
          <Text
            text={"Anti-Money Laundering Policy"}
            weight={800}
            className={`text-[#282B59] text-left my-8 text-[16px] lg:text-[25px]`}
          />

          <p className="leading-7 font-normal !no-underline font-nunito text-base not-italic normal-nums tracking-normal indent-0 break-normal text-[#53557A] text-left my-2 text-[14px] lg:text-[24px]">
            We, at OrnaMap Technologies Limited are committed to providing a safe and secure platform for our users
            to plan their trips and fund their cowry cards in Nigeria.
            This Anti-Money Laundering (AML) Policy outlines
            the measures we take
            to prevent our app from being used for illegal activities such as money laundering
          </p>

          <div className="flex w-full flex-col lg:pl-8">
            <p className="text-[#53557A] my-2 text-[14px] pl-8 text-justify lg:text-[16px]">
              <span className="font-extrabold">1. Customer Due Diligence (CDD)<br /> </span>
            </p>

            <ul>
              <li>
                <p className="text-[#53557A] my-2 text-[14px] pl-8 text-justify lg:text-[16px]">
                  <span className="font-extrabold">a. Verification: </span>
                  We require all users to register for an account with OrnaMap Technologies Limited.
                  During registration, we collect basic user information such as name, phone number, and email
                  address.
                </p>
              </li>

              <li>
                <p className="text-[#53557A] my-2 text-[14px] pl-8 text-justify lg:text-[16px]">
                  <span className="font-extrabold">b. Cowry Wallet Verification: </span>
                  For users paying into their cowry wallets with OrnaMap Technologies
                  Limited, we will implement procedures to verify the ownership and legitimacy of the cowry wallet.
                  This may involve collaborating with the cowry wallet provider to obtain relevant information.
                </p>
              </li>

              <li>
                <p className="text-[#53557A] my-2 text-[14px] pl-8 text-justify lg:text-[16px]">
                  <span className="font-extrabold">c. Suspicious Activity Monitoring: </span>
                  We monitor user activity for suspicious patterns that could indicate
                  money laundering, such as anonymous payment methods or frequent bookings with different
                  accounts originating from the same location.
                </p>
              </li>

            </ul>

            <p className="text-[#53557A] my-4 text-[14px] pl-8 text-justify lg:text-[16px]">
              <span className="font-extrabold">2. Customer Due Diligence (CDD)<br /> </span>

              We set limits on the amount a user can fund their wallets with through the
              app in a single transaction.
              This helps us identify and flag unusually large transactions that could
              be suspicious.
            </p>

            <p className="text-[#53557A] my-4 text-[14px] pl-8 text-justify lg:text-[16px]">
              <span className="font-extrabold">3. KYC/AML for Cowry Wallets<br /> </span>

              We will rely on Cowry to perform KYC (Know Your Customer) and
              AML checks on their users. This ensures that users linking their Cowry wallets to OrnaMap have
              already undergone identity verification.
            </p>

            <p className="text-[#53557A] my-4 text-[14px] pl-8 text-justify lg:text-[16px]">
              <span className="font-extrabold">4. Reporting<br /> </span>

              We are committed to reporting any suspicious activity to the relevant authorities.
              We
              will maintain detailed records of all transactions
              conducted through the OrnaMap platform for a
              period of at least two (2) years.
            </p>

            <p className="leading-7 font-extrabold !no-underline font-nunito text-base not-italic normal-nums tracking-normal indent-0 break-normal text-black text-left my-2 text-[16px] lg:text-[20px] lg:mt-4">
              Our Commitment
            </p>

            <p className="text-[#53557A] my-1 text-[14px] pl-8 text-justify lg:text-[16px]">
              We are committed to upholding the highest standards of AML compliance.
              We regularly review and update
              our policies and procedures to stay ahead of evolving money laundering techniques.
              We also work closely
              with law enforcement agencies to combat financial crime.
            </p>

            <p className="text-[#53557A] my-1 text-[14px] pl-8 text-justify lg:text-[16px]">
              By using OrnaMap, you agree to comply with this AML Policy.
              We reserve the right to suspend or terminate
              your account if we suspect you are using our platform for illegal activities.
            </p>

            <p className="text-[#53557A] my-1 text-[14px] pl-8 text-justify lg:text-[16px]">
              OrnaMap is committed to providing a safe and secure platform for all our users.
            </p>
          </div>
        </div>

        <div className={`my-8`}>
          <p className={`terms-text-small text-[14px] lg:text-[24px]`}>
            These Terms and Conditions create the entire agreement between you and OrnaMap. regarding your use of the
            App. If you have any questions or concerns about these Terms, please contact us at
            <a
              href="mailto:support@ornamap.com"
              className={`text-[#008751] hover:text-black`}
            > support@ornamap.com</a>
          </p>
        </div>

      </div>
    </Fragment>
  )
}

export default TermsAndConditions