import {useLocation} from "react-router-dom";
import {useQuery} from "react-query";
import {axiosGetRequest} from "../lib/axios";

export const useRaveWebHookHandler = () => {
  const location = useLocation();
  const tx_ref = new URLSearchParams(location.search).get('tx_ref');
  const transaction_id = new URLSearchParams(location.search).get('transaction_id');

  const {
    data: transactionData,
    isLoading: isTransactionLoading,
  } = useQuery(
    ['getTransactionData', tx_ref, transaction_id],
    function () {
      return axiosGetRequest(
        `/transaction/verify/rave?transactionReference=${tx_ref}&transactionId=${transaction_id}`
      );
    }
  );

  return {
    tx_ref,
    transaction_id,
    transactionData,
    isTransactionLoading
  }
}