interface SpeakerSVGProps {
  height?: number;
  width?: number;
}

export const SpeakerSVG = ({height=32, width=32}: SpeakerSVGProps) => {
  return (
    <svg width={height} height={width} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M14.6951 9.45558C13.3068 8.95822 12.4353 9.11155 12.0429 9.50722C11.6505 9.90288 11.5045 10.7756 12.0134 12.1598C12.5007 13.4851 13.5168 15.0404 14.9686 16.4802C16.4204 17.9199 17.9842 18.9231 19.3135 19.3993C20.7019 19.8967 21.5733 19.7434 21.9657 19.3477C22.3581 18.952 22.5042 18.0794 21.9952 16.6952C21.508 15.3699 20.4918 13.8145 19.04 12.3748C17.5882 10.935 16.0244 9.93182 14.6951 9.45558ZM15.1701 8.12978C16.7336 8.68988 18.4672 9.82332 20.0317 11.3748C21.5961 12.9262 22.7439 14.6504 23.3171 16.2092C23.8686 17.7092 23.9714 19.3253 22.9657 20.3394C21.96 21.3535 20.3431 21.2641 18.8386 20.7252C17.275 20.165 15.5414 19.0316 13.977 17.4802C12.4125 15.9287 11.2647 14.2046 10.6916 12.6457C10.1401 11.1458 10.0372 9.52965 11.0429 8.51552C12.0487 7.50142 13.6656 7.59078 15.1701 8.12978Z"
            fill="#282B59"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M10.5851 9.10938L11.892 9.63414L6.75802 22.4208C6.38429 23.3517 6.12245 24.0056 5.97342 24.4988C5.81765 25.0143 5.83835 25.1979 5.85852 25.2577C5.95339 25.539 6.17722 25.7579 6.46062 25.8464C6.52079 25.8652 6.70482 25.8818 7.21672 25.7144C7.70645 25.5543 8.35429 25.2778 9.27649 24.8832L21.6776 19.5766L22.2317 20.8714L9.79695 26.1923C8.91602 26.5693 8.21062 26.8712 7.65435 27.053C7.11319 27.2299 6.55519 27.3514 6.04072 27.1907C5.32689 26.9677 4.76302 26.4164 4.52402 25.7078C4.35179 25.197 4.46059 24.6365 4.62529 24.0914C4.79459 23.5312 5.08045 22.8192 5.43749 21.93L10.5851 9.10938Z"
            fill="#282B59"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M16.8633 23.8369C16.7828 23.6206 16.6568 23.3482 16.465 22.9358L17.7421 22.342L17.7535 22.3666C17.9308 22.7479 18.081 23.071 18.1833 23.3459C18.2905 23.634 18.3672 23.9248 18.3602 24.2407C18.3488 24.7591 18.1651 25.259 17.8382 25.6614C17.639 25.9068 17.3923 26.0787 17.1241 26.2289C16.8681 26.3722 16.5445 26.5212 16.1625 26.697L14.8479 27.3021C14.4658 27.478 14.1415 27.6274 13.865 27.7286C13.5747 27.8349 13.2819 27.9099 12.9636 27.9002C12.4806 27.8855 12.0141 27.7214 11.6283 27.4304C11.3729 27.2378 11.1907 26.9932 11.0299 26.7262C10.8763 26.4711 10.7144 26.1467 10.5231 25.7633L10.511 25.7389C10.3374 25.3909 10.4787 24.968 10.8267 24.7944C11.1747 24.6208 11.5976 24.7622 11.7712 25.1102C11.9779 25.5245 12.115 25.7982 12.2363 25.9997C12.3538 26.1947 12.4255 26.2677 12.4763 26.306C12.6295 26.4215 12.8147 26.4867 13.0064 26.4925C13.0714 26.4945 13.172 26.4825 13.3808 26.4061C13.5976 26.3267 13.8702 26.2018 14.2839 26.0114L15.549 25.429C15.9622 25.2388 16.2345 25.1129 16.4359 25.0001C16.6305 24.8912 16.7049 24.823 16.745 24.7736C16.8748 24.6138 16.9477 24.4154 16.9522 24.2096C16.9536 24.146 16.9411 24.0459 16.8633 23.8369Z"
            fill="#282B59"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M17.9173 3.52954C18.3062 3.52954 18.6215 3.84481 18.6215 4.23371V6.45867C18.6215 6.84757 18.3062 7.16284 17.9173 7.16284C17.5284 7.16284 17.2131 6.84757 17.2131 6.45867V4.23371C17.2131 3.84481 17.5284 3.52954 17.9173 3.52954Z"
            fill="#282B59"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M25.4951 6.66912C25.7701 6.94412 25.7701 7.38998 25.4951 7.66498L23.9218 9.23832C23.6468 9.51332 23.201 9.51332 22.926 9.23832C22.651 8.96332 22.651 8.51745 22.926 8.24245L24.4993 6.66912C24.7743 6.39415 25.2201 6.39415 25.4951 6.66912Z"
            fill="#282B59"/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M25.0007 14.2462C25.0007 13.8573 25.316 13.542 25.7049 13.542H27.9299C28.3188 13.542 28.634 13.8573 28.634 14.2462C28.634 14.6351 28.3188 14.9503 27.9299 14.9503H25.7049C25.316 14.9503 25.0007 14.6351 25.0007 14.2462Z"
            fill="#282B59"/>
    </svg>
  )
}