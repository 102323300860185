import {Fragment} from "react";
import AppleLogo from "../assets/img/email/apple logo.svg";
import FacebookLogo from "../assets/img/email/Facebook Logo.svg";
import InstagramLogo from "../assets/img/email/Instagram Logo.svg";
import OrnaMapLogo from "../assets/img/email/logo.svg";
import PlayStoreLogo from "../assets/img/email/play store.svg";
import TikTokLogo from "../assets/img/email/TikTok Logo.svg";
import TwitterLogo from "../assets/img/email/Twitter Logo.svg";

const EmailImages = () => {
  return (
    <Fragment>
      <div className={`grid grid-cols-3 gap-4`}>
        <img src={AppleLogo} alt={AppleLogo}/>

        <img src={FacebookLogo} alt={FacebookLogo}/>

        <img src={InstagramLogo} alt={InstagramLogo}/>

        <img src={OrnaMapLogo} alt={OrnaMapLogo}/>

        <img src={PlayStoreLogo} alt={PlayStoreLogo}/>

        <img src={TikTokLogo} alt={TikTokLogo}/>

        <img src={TwitterLogo} alt={TwitterLogo}/>
      </div>
    </Fragment>
  )
}

export default EmailImages;