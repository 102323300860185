import { Fragment } from "react";
import Text from "../../global/Text";
import { HiArrowSmRight } from 'react-icons/hi'
import DownloadStoreOne from '../../../assets/img/homepage/img-download-store-1.png'
import DownloadStoreTwo from '../../../assets/img/homepage/img-download-store-2.png'

const DownloadPlaystore = () => {
  return (
    <Fragment>
      <div className="max-w-screen-xl px-2 gap-16 overflow-hidden">
        <div className={`grid grid-cols-1 items-center justify-center w-full bg-[#12A628] px-5 gap-16 overflow-hidden rounded-lg lg:grid-cols-2 lg:max-h-[600px] lg:rounded-[16px] py-4 lg:py-0 lg:max-h-[100%]`}>
          <div className={`h-full flex flex-col justify-center items-center lg:items-start lg:px-5`}>
            <Text
              text={`Download on App store & Play store`}
              weight={800}
              className={`text-white max-w-xs mb-2 !text-[24px] leading-[33px] text-center lg:!text-2xl lg:text-left`}
            />

            <Text
              text={`To unlock the full potential of our train and ferry travel app, download it on the App Store or 
            Google Play Store today. Get access to additional features and enhance your travel planning experience`}
              weight={400}
              className={`text-[#EAEAEE] my-2 !text-[16px] text-center lg:!text-[18px] lg:text-left`}
            />

            <div className="flex flex-row items-center">
              <a
                type="button"
                className="text-[#282B59] bg-white border border-gray-200 mr-2 mb-2 my-4 font-medium !rounded-[8px]
              px-[24px] py-[19px] text-center inline-flex items-center text-lg min-w-[201px] !text-[18px]
              hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-100 lg:!text-sm"
                href={`#discover`}
                style={{
                  fontWeight: 700,
                  fontSize: '19px',
                  background: 'var(--white-white-1, #FFF)'
                }}
              >
                Download OrnaMap

                <HiArrowSmRight size={20} className={`ml-2`}/>
              </a>
            </div>
          </div>

          <div className={`grid-cols-2 gap-8 hidden lg:grid`}>
            <div className={`flex flex-col h-full w-full`}>
              <img src={DownloadStoreOne} alt={`Download Store One`} loading={`lazy`}/>
            </div>

            <div className={`flex flex-col h-full w-full`}>
              <img src={DownloadStoreTwo} alt={`Download Store One`} className={`h-full`} loading={`lazy`}/>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default DownloadPlaystore