import { Fragment } from "react";
import BackgroundShortImage from "../assets/img/global/bg-hero-short-section.jpg";
import HeaderWithBgImg from "../components/global/HeaderWithBgImg";
import MissionStatement from "../components/about/MissionStatement";
import OrnaMapStory from "../components/about/OrnaMapStory";
import FerryArrival from "../components/home/page_components/FerryArrival";
import GotQuestions from "../components/home/page_components/GotQuestions";
import DiscoverOrna from "../components/home/DiscoverOrna";
import Footer from "../components/global/Footer";

const AboutUs = () => {
  return (
    <Fragment>
      <HeaderWithBgImg bgImg={BackgroundShortImage}/>

      <main>
        <section id="blog_details_page" className="flex min-h-[400px] items-baseline justify-center px-0 py-10 mt-[70px] lg:px-4 lg:px-10 lg:pb-20 lg:pt-[155px] lg:mt-0">
          <MissionStatement/>
        </section>

        <section id={`our-story`} className={`flex min-h-[500px] items-baseline justify-center px-0 py-10`}>
          <OrnaMapStory/>
        </section>

        <section id="ferry-arrival" className="hidden items-center justify-center py-10 bg-white px-4 py-10 lg:px-[100px] lg:py-20 lg:flex">
          <FerryArrival/>
        </section>

        <section id="got-options" className="hidden items-center justify-center py-10 bg-white px-4 py-10 lg:px-10 lg:py-20 lg:flex">
          <GotQuestions/>
        </section>

        <section
            id="discover-orna"
            className={`flex items-center justify-center flex-col pt-5 px-4 min-h-[260px] bg-cover lg:bg-auto lg:px-0
           lg:px-10 bg-[url(https://res.cloudinary.com/zalajobi/image/upload/v1688676997/tupxfdqlqubfcjmehnya.svg)] lg:min-h-[500px]`}
        >
          <DiscoverOrna/>
        </section>
      </main>

      <Footer/>
    </Fragment>
  );
}

export default AboutUs;