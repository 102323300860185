import React from "react";
import BgSearch from '../../../assets/img/homepage/bg-service-search.svg'
import BgRoutes from '../../../assets/img/homepage/bg-service-routes.svg'
import BgOnPt from '../../../assets/img/homepage/bg-service-onpt.svg'
import ImgSearch from '../../../assets/img/homepage/img-service-search.png'
import ImgPt from '../../../assets/img/homepage/img-service-onpt.png'
import ImgRoutes from '../../../assets/img/homepage/img-service-routes.png'
import Text from "../../global/Text";

const ServiceTerms = () => {
    return (
        <React.Fragment>
            <div
                className="max-w-screen-xl items-start px-2 justify-center w-full grid grid-cols-1 lg:grid-cols-3 gap-4">
                <div className="flex flex-col items-center justify-center my-3 lg:my-0">
                    <div style={{backgroundImage: `url(${BgSearch})`}}
                         className="border border-solid border-[#282B59] rounded-[24px] box-border w-full flex justify-center bg-white items-end h-[260px] lg:h-[300px]">
                        <img
                            src={ImgSearch}
                            alt="service term search img content"
                            className={`h-[245px] lg:h-[280px]`}
                            loading={`lazy`}
                        />
                    </div>

                    <Text
                        text={'Search'}
                        weight={800}
                        className="large-text-typography mt-2 lg:mt-5"
                    />

                    <Text
                        text={'Enter your destination'}
                        weight={400}
                        className="small-text-typography"
                    />
                </div>

                <div className="flex flex-col items-center justify-center my-3 lg:my-0">
                    <div style={{backgroundImage: `url(${BgRoutes})`}}
                         className="border border-solid border-[#282B59] rounded-[24px] box-border w-full flex justify-center bg-white items-end h-[260px] lg:h-[300px]">
                        <img
                            src={ImgRoutes}
                            alt="service term search img content"
                            className={`h-[245px] lg:h-[280px]`}
                            loading={`lazy`}
                        />
                    </div>

                    <Text
                        text={'Fastest & Cheapest Route'}
                        weight={800}
                        className="large-text-typography mt-2 lg:mt-5"
                    />

                    <Text
                        text={'Choose from the recommended routes, including train and ferry options.'}
                        weight={400}
                        className="small-text-typography"
                    />
                </div>

                <div className="flex flex-col items-center justify-center my-3 lg:my-0">
                    <div style={{backgroundImage: `url(${BgOnPt})`}}
                         className="border border-solid border-[#282B59] rounded-[24px] box-border w-full flex justify-center bg-white items-start h-[260px] lg:h-[300px]">
                        <img
                            src={ImgPt}
                            alt="Stops & Next Train"
                            className={`h-[245px] lg:h-[280px]`}
                            loading={`lazy`}
                        />
                    </div>

                    <Text
                        text={'Stops & Next Train'}
                        weight={800}
                        className="large-text-typography mt-2 lg:mt-5"
                    />

                    <Text
                        text={'See the departure and arrival times, as well as the destination stops, for your chosen train or ferry.'}
                        weight={400}
                        className="small-text-typography"
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

export default ServiceTerms