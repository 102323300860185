import {Fragment} from "react";
import {useBlogDetailsPage} from "../hooks/useBlogDetailsPage";
import BackgroundShortImage from "../assets/img/global/bg-hero-short-section.jpg";
import HeaderWithBgImg from "../components/global/HeaderWithBgImg";
import Footer from "../components/global/Footer";
import Text from "../components/global/Text";
import {Typography} from "@material-tailwind/react";
import moment from "moment/moment";

const BlogDetails = () => {
  const {
    blogData,
  } = useBlogDetailsPage();

  return (
      <Fragment>
        <HeaderWithBgImg bgImg={BackgroundShortImage}/>

        <main>
          <section id="blog_details_page" className="flex min-h-[500px] items-baseline justify-center px-0 py-10 mt-[70px] lg:px-4 lg:px-10 lg:pb-20 lg:pt-[155px] lg:min-h-[900px] lg:mt-0">
            <div className={`max-w-screen-xl flex flex-col w-full min-h-[400px] h-full px-4`}>
              <Text
                  weight={800}
                  className={`text-[#282B59] text-center leading-normal lg:text-[30px]`}
                  text={`Explore Seamless Travel Experiences with OrnaMap: Your Ultimate Journey Companion!`}
              />

              <div className={`flex flex-col`}>
                <img
                    className="h-96 w-full rounded-lg object-cover object-center rounded-t-md bg-clip-padding backdrop-filter backdrop-blur-lg bg-opacity-40"
                    src={blogData?.imgUrl ?? ''}
                    alt={blogData?.imgUrl ?? ''}
                    loading={`eager`}
                />

                <div className={`flex w-full mt-2 items-center justify-center`}>
                  <Typography className="font-bold font-nunito">{blogData?.writer ?? ''} </Typography>

                  <div className={`w-[1px] h-[25px] bg-[#9495AC] mx-2`}></div>

                  <Typography className="font-normal font-nunito"> {moment(blogData?.created_at).format('MMM Do YYYY')}</Typography>
                </div>
              </div>

              {/*Main Content Header*/}
              {typeof blogData?.introContent === 'string' ? (
                  <Typography className={`font-nunito font-normal text-[#53557A] text-justify leading-normal my-2 lg:text-[18px]`}>
                    {blogData?.introContent ?? ''}
                  </Typography>
              ) : (
                  blogData?.introContent?.map((paragraph, idx) => (
                      <Typography key={idx} className={`font-nunito font-normal text-[#53557A] text-justify leading-normal my-2 lg:text-[18px]`}>
                        {paragraph ?? ''}
                      </Typography>
                  ))
              )}

              {/*Main Content*/}
              {blogData?.content?.length !== 0 && blogData?.content?.map((content, index) => (
                  <div className={`flex flex-col w-full h-full my-3`}>
                    {/*Image*/}
                    {content.img_url && (
                        <img
                            className="h-96 w-full rounded-lg object-cover object-center rounded-t-md bg-clip-padding backdrop-filter backdrop-blur-lg bg-opacity-40"
                            src={content.img_url}
                            alt={content.img_url}
                            loading={`lazy`}
                        />
                    )}

                    {/*Header*/}
                    <Typography className={`font-nunito font-bold text-[#282B59] text-justify leading-normal my-2 lg:text-[18px]`}>
                      {content?.header ?? ''}
                    </Typography>

                    {/*Content*/}
                    {typeof content?.paragraphs === 'string' ? (
                            <Typography className={`font-nunito font-normal text-[#53557A] text-justify leading-normal my-2 lg:text-[18px]`}>
                              {content?.paragraphs ?? ''}
                            </Typography>
                    ) : (
                        content?.paragraphs?.map((paragraph, idx) => (
                            <Typography key={idx} className={`font-nunito font-normal text-[#53557A] text-justify leading-normal my-2 lg:text-[18px]`}>
                              {paragraph ?? ''}
                            </Typography>
                        ))
                    )}

                    {content?.sub_content?.length !== 0 && content?.sub_content?.map((subContent, idx) => (
                        <div className={`flex flex-col w-full h-full my-3`}>
                          {/*Image*/}
                          {subContent?.img_url && (
                              <img
                                  className="h-96 w-full rounded-lg object-cover object-center rounded-t-md bg-clip-padding backdrop-filter backdrop-blur-lg bg-opacity-40"
                                  src={subContent?.img_url}
                                  alt={subContent?.img_url}
                                  loading={`lazy`}
                              />
                          )}

                          {/*Header*/}
                          <Typography className={`font-nunito font-bold text-[#282B59] text-justify leading-normal my-2 lg:text-[18px]`}>
                            {subContent?.header ?? ''}
                          </Typography>

                          {/*Content*/}
                          {typeof subContent?.paragraphs === 'string' ? (
                              <Typography className={`font-nunito font-normal text-[#53557A] text-justify leading-normal my-2 lg:text-[18px]`}>
                                {subContent?.paragraphs ?? ''}
                              </Typography>
                          ) : (
                              subContent?.paragraphs?.map((paragraph, idx) => (
                                  <Typography className={`font-nunito font-normal text-[#53557A] text-justify leading-normal my-2 lg:text-[18px]`}>
                                    {paragraph ?? ''}
                                  </Typography>
                              ))
                          )}
                        </div>
                    ))}

                  </div>
              ))}
            </div>
          </section>

          <Footer/>
        </main>
      </Fragment>
  )
}

export default BlogDetails;