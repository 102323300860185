import { Fragment } from "react"
import HeaderWithBgImg from "../components/global/HeaderWithBgImg";
import BackgroundShortImage from '../../src/assets/img/global/bg-hero-short-section.jpg'
import PageSubheader from "../components/global/PageSubheader";
import TermsAndConditions from "../components/terms/TermsAndConditions";
import Footer from "../components/global/Footer";

const Terms = () => {
  return (
    <Fragment>
      <HeaderWithBgImg bgImg={BackgroundShortImage}/>

      <main>
        <PageSubheader header={`Terms & Condition`} lastUpdate={`Updated: May 27th, 2023`}/>

        <section id="privacy-items" className="w-full flex items-center justify-center px-4 py-10 bg-[#EAEAEE] lg:px-[100px] lg:py-20">
          <TermsAndConditions/>
        </section>
      </main>

      <Footer/>
    </Fragment>
  )
}
export default Terms