import {useState, Fragment} from "react";
import Text from "../../global/Text";
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'
import {faqQuestions} from "../../../lib/constants/constants";

const GotQuestions = () => {
  const [currentQuestion, setCurrentQuestion] = useState<number | null>(null);

  const updateCurrentQuestion = (question:number) => {
    if (question === currentQuestion)
      setCurrentQuestion(null)
    else
      setCurrentQuestion(question)
  }

  return (
    <Fragment>
      <div className={`max-w-screen-xl flex flex-col items-center justify-center w-full p-2 lg:p-10`}>
        <Text
          text={`Got Questions?`}
          weight={800}
          className={`text-[#282B59] text-center !text-[24px] mb-5 leading-[33px] lg:!text-5xl lg:mb-10 lg:leading-[65px]`}
        />

        <Text
          text={`Some of most frequently asked questions.`}
          weight={400}
          className={`text-[#53557A] text-center mb-5 !text-[16px] leading-[22px] lg:leading-[33px] lg:!text-3xl lg:mb-10`}
        />

        {faqQuestions && faqQuestions.map((value, idx) => {
          return (
            <div
              className={`max-w-4xl w-full border-[3px] border-solid border-[#282B59] box-border rounded-[30px] p-4 my-3 flex flex-col hover:cursor-pointer lg:p-8`}
              key={idx}
              onClick={() => updateCurrentQuestion(idx)}
            >
              <div className={`flex flex-row w-full items-center`}>
                <Text
                  text={value?.question}
                  weight={800}
                  className={`text-[#282B59] text-[16px] leading-[22px] lg:leading-[34px] lg:text-2xl`}
                />

                <div
                  className={`flex items-center justify-center p-2 border-2 border-solid border-[#282B59] ml-auto rounded-full`}
                >
                  <MdOutlineKeyboardArrowDown size={20}/>
                </div>
              </div>

              {currentQuestion === idx && (
                <Text
                  text={value?.answer}
                  weight={400}
                  className={`text-[#53557A] mt-3 text-justify text-[14px] leading-[19px] lg:leading-[25px] lg:text-lg`}
                />
              )}
            </div>
          )
        })}
      </div>
    </Fragment>
  )
}

export default GotQuestions;