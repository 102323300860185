import {initializeApp} from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCe9xiI6Zj6ziYSxeyXolkq4N9k4yPiWRY",
  authDomain: "ornamap-368401.firebaseapp.com",
  projectId: "ornamap-368401",
  storageBucket: "ornamap-368401.appspot.com",
  messagingSenderId: "629700377766",
  appId: "1:629700377766:web:14f281aaca644396ad0867",
  measurementId: "G-191S1JQ87M"
};

const app = initializeApp(firebaseConfig)
const analytics: any = getAnalytics(app)

export { analytics, logEvent };