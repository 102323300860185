import { Fragment } from "react";
import { BsInstagram, BsTwitter, BsTiktok } from 'react-icons/bs';
import { FaFacebookF } from 'react-icons/fa';
import Text from "./Text";

const Footer = () => {
  return (
    <Fragment>

      <div className={`w-full flex flex-col items-center bg-[#282B59]`}>
        <div className={`w-full p-5 grid grid-cols-[60%_40%] lg:grid-cols-[40%_60%] lg:gap-4 lg:py-20 lg:px-10`}>
          <div className="w-full flex flex-col pb-8 lg:pb-0">
            {/*Call*/}
            <div className="flex flex-col justify-start overflow-hidden mb-2">
              <Text
                text={`Call US:`}
                className="w-full whitespace-nowrap pr-1 text-left self-center text-[16px] leading-[22px] text-white lg:leading-[25px] lg:text-[18px]"
                weight={700}
              />

              <a
                href="tel:+2347051334957"
                target="_blank"
                className="flex items-center col-span-2 my-1 text-white lg:col-span-1"
              >
                <Text
                  text={`+2347051334957`}
                  className="whitespace-nowrap self-center text-[16px] leading-[22px] text-white hover:text-[#696B8B] lg:leading-[25px] lg:text-[18px]"
                  weight={700}
                />
              </a>
            </div>

            {/*Email*/}
            <div className="flex flex-col justify-start overflow-hidden mb-2">
              <Text
                text={`Email US:`}
                className="w-full whitespace-nowrap pr-1 text-left self-center text-[16px] leading-[22px] text-white lg:leading-[25px] lg:text-[18px]"
                weight={700}
              />

              <a
                href="mailto:support@ornamap.com"
                target="_blank"
                className="flex items-center col-span-2 my-1 text-white lg:col-span-1"
              >
                <Text
                  text={`support@ornamap.com`}
                  className="whitespace-nowrap self-center text-[16px] leading-[22px] text-white hover:text-[#696B8B] lg:leading-[25px] lg:text-[18px]"
                  weight={700}
                />
              </a>
            </div>

            {/*Address*/}
            <div className="flex flex-col justify-start overflow-hidden mt-2">
              <Text
                text={`Address:`}
                className="w-full whitespace-nowrap pr-1 text-left self-center text-[16px] leading-[22px] text-white lg:leading-[25px] lg:text-[18px]"
                weight={700}
              />

              <p
                className="!no-underline font-nunito text-left text-base not-italic normal-nums tracking-normal indent-0 break-normal w-full whitespace-nowrap self-center text-[16px] leading-[22px] text-white hover:text-[#696B8B] lg:leading-[25px] lg:text-[18px] text-auto"
                style={{
                  "fontWeight": 700,
                  // @ts-ignore
                  "textWrap": "auto"
                }}
              >
                3, Folorusho Kazeem Close, Lagos Nigeria
              </p>
            </div>
          </div>

          <div className="flex flex-col items-baseline gap-4 lg:grid lg:grid-cols-4">
            <div className="w-full h-full flex items-baseline">
              <a href="#" className="flex items-center col-span-2 my-1 mx-auto lg:col-span-1">
                <Text
                  text={`About Us`}
                  className="whitespace-nowrap !font-normal self-center text-[16px] leading-[22px] text-white hover:text-[#696B8B] lg:leading-[25px] lg:!font-bold lg:text-[18px]"
                />
              </a>
            </div>

            <div className="w-full h-full flex items-baseline">
              <a href="#" className="flex items-center col-span-2 my-1 mx-auto lg:col-span-1">
                <Text
                  text={`Contact Us`}
                  weight={800}
                  className="whitespace-nowrap !font-normal self-center text-[16px] leading-[22px] text-white hover:text-[#696B8B] lg:leading-[25px] lg:!font-bold lg:text-[18px]"
                />
              </a>
            </div>

            <div className="w-full h-full flex items-baseline">
              <a href="/terms" className="flex items-center col-span-2 my-1 mx-auto lg:col-span-1">
                <Text
                  text={`Terms & Conditions`}
                  size={`2xl`}
                  className="whitespace-nowrap !font-normal self-center text-[16px] leading-[22px] text-white hover:text-[#696B8B] lg:leading-[25px] lg:!font-bold lg:text-[18px]"
                />
              </a>
            </div>

            <div className="w-full h-full flex items-baseline">
              <a href="/privacy" className="flex items-center col-span-2 my-1 mx-auto lg:col-span-1">
                <Text
                  text={`Privacy & Policy`}
                  size={`2xl`}
                  className="whitespace-nowrap !font-normal self-center text-[16px] leading-[22px] text-white hover:text-[#696B8B] lg:leading-[25px] lg:!font-bold lg:text-[18px]"
                />
              </a>
            </div>
          </div>
        </div>

        <div className={`flex w-full border border-solid border-[#696B8B]`}/>

        <div className={`flex flex-row items-center justify-center w-[50%] p-20`}>
          <div className={`mx-5`}>
            <a href="https://www.facebook.com/ornamap" target={"_blank"} rel={"noreferrer"}>
              <FaFacebookF size={30} className={`text-[#696B8B] hover:text-white hover:cursor-pointer`}/>
            </a>
          </div>

          <div className={`mx-5`}>
            <a href="https://www.instagram.com/ornamap" target={"_blank"} rel={"noreferrer"}>
              <BsInstagram size={30} className={`text-[#696B8B] hover:text-white hover:cursor-pointer`}/>
            </a>
          </div>

          <div className={`mx-5`}>
            <a href="https://www.twitter.com/ornamapapp" target={"_blank"} rel={"noreferrer"}>
              <BsTwitter size={30} className={`text-[#696B8B] hover:text-white hover:cursor-pointer`}/>
            </a>
          </div>

          <div className={`mx-5`}>
            <a href="https://www.tiktok.com/@ornamap" target={"_blank"} rel={"noreferrer"}>
              <BsTiktok size={30} className={`text-[#696B8B] hover:text-white hover:cursor-pointer`}/>
            </a>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Footer