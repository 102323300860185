import {Fragment} from "react";

const AppleAppSiteAssociation = () => {
    const jsonObject = {
        "applinks": {
            "apps": [],
            "details": [
                {
                    "appIDs": ["ZWXH8BD23X.com.ornamap.ios", "ZWXH8BD23X.com.ornamap.ios-debug"],
                    "components": [
                        {
                            "/": "*"
                        }
                    ]
                }
            ]
        }
    };

    const jsonString = JSON.stringify(jsonObject, null, 2);
    return (
        <pre>{jsonString}</pre>
    );
}

export default AppleAppSiteAssociation;