import {Fragment} from "react";
import AboutUsImage from '../../assets/img/aboutUs/story.png'
import Text from "../global/Text";

const OrnaMapStory = () => {
  return (
      <Fragment>
        <div className={`max-w-screen-xl px-[20px] flex w-full min-h-[400px] lg:grid lg:grid-cols-2 gap-4 lg:gap-10 lg:px-0`}>
          <div className={`hidden w-full h-[350px] lg:flex`}>
            <img
                src={AboutUsImage}
                alt={AboutUsImage}
                width={400}
                height={400}
            />
          </div>

          <div className={`flex flex-col justify-start items-center lg:items-baseline`}>

            <Text
                text={`A Short Story`}
                weight={900}
                className={`text-[30px] font-extrabold text-[#282B59] mb-5 lg:text-[55px] lg:font-black`}
            />

            <Text
                text={`OrnaMap, established as a revolutionary 'Transit Navigation' app, embarked on its mission to redefine how people experience travel. Our journey began with a singular focus on providing accurate and real-time transit information.`}
                weight={400}
                className={`text-[16px] text-[#53557A] my-2 lg:text-[18px]`}
            />

            <Text
                text={`In January, 2021, we took a significant step forward by introducing advanced features, including real-time train and ferry schedules. This enhancement marked a turning point in making travel planning more accessible and efficient for our growing user community.`}
                weight={400}
                className={`text-[16px] text-[#53557A] my-2 lg:text-[18px]`}
            />

            <Text
                text={`OrnaMap stands as a reliable companion for thousands of users, offering seamless navigation through trains and ferries. Our commitment is to empower users with the tools they need to make informed travel decisions effortlessly.`}
                weight={400}
                className={`text-[16px] text-[#53557A] my-2 lg:text-[18px]`}
            />

            <Text
                text={`At OrnaMap, we believe in simplifying the complexities of travel, and our mission is to provide everyone with the power to navigate their journeys with confidence. Join us on this exciting adventure in transit navigation.`}
                weight={400}
                className={`text-[16px] text-[#53557A] my-2 lg:text-[18px]`}
            />

            <Text
                text={`Thank you for choosing OrnaMap for your travel needs! 🌍`}
                weight={400}
                className={`text-[16px] text-[#53557A] my-2 lg:text-[18px]`}
            />

          </div>
        </div>
      </Fragment>
  )
}

export default OrnaMapStory;