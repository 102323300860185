import {Fragment} from "react";
import Text from "../global/Text";
import PelumiAboutUs from "../../assets/img/aboutUs/Pelumi.png";

const MissionStatement = () => (
  <Fragment>
    <div className={`max-w-screen-xl flex flex-col px-[20px] gap-4 lg:gap-10 lg:grid-cols-2 lg:grid lg:px-0`}>
      <div className={`flex flex-col justify-start items-center lg:items-baseline`}>
        <Text
            weight={800}
            text={`Our Mission:`}
            className={`text-[#53557A] text-[16px] px-[16px] py-[8px] bg-[#EAEAEE] rounded-[30px] lg:text-[25px]`}
        />

        <Text
            weight={900}
            text={`To empower seamless travel experiences with OrnaMap`}
            className={`text-[#282B59] max-w-[290px] font-extrabold text-[30px] text-center mt-8 leading-normal lg:font-black lg:leading-[60px] lg:text-[55px] lg:text-left lg:max-w-full`}
        />
      </div>

      <div className={` flex items-center justify-center lg:w-full lg:h-full`}>
        <img src={PelumiAboutUs} alt={PelumiAboutUs}/>
      </div>
    </div>
  </Fragment>
);

export default MissionStatement;