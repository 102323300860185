import React from "react";
import {MdCancel} from "react-icons/md";

interface HamburgerButtonProps {
  click: () => void
  text: string
    opened: boolean
}

const HamburgerButton = ({click, text, opened=false}:HamburgerButtonProps) => {
  return (
    <React.Fragment>
      <button
        data-collapse-toggle="navbar-sticky"
        type="button"
        className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        aria-controls={`navbar-sticky`}
        aria-expanded={false}
        onClick={click}
      >
          {opened ? (
              <>
                  <span className="sr-only">{text}</span>
                  <MdCancel size={30} color={`#B2B3C3`} className={`bg-white`}/>
              </>
          ) : (
              <>
                  <span className="sr-only">{text}</span>
                  <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path>
                  </svg>
              </>
          )}
      </button>
    </React.Fragment>
  )
}

export default HamburgerButton;